
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "user-showProfile",
  components: {},
  setup() {
    const route = useRoute();
    const employeeID = route.params.id;
    const entityID = route.params.entity;
    return { employeeID, entityID };
  },
  data() {
    return {
      employee: {
        user_id: "",
        user: {
          active_status: 1,
        },
        roles: [],
      },
      search: "",
      tableData: [],
    };
  },
  methods: {},
});
